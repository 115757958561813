<template>
  <!-- 植物记录 -->
  <div class="cont">
    <div class="record">
      <TopBanner @toggle="showMask = !showMask"/>
      <template
        v-if="longTextInfo"
      >
        <div class="page2">
          <div class="dom1">{{ longTextInfo.title }}</div>
          <div class="dom4">
            <span>浏览量：{{ longTextInfo.views }}</span>
            <span>发文时间：{{ dataFormat(new Date(longTextInfo.createTime), 'yyyy.MM.dd') }}</span>
          </div>
          <div
            class="dom2"
            @click="
              $router.push({
                path: '/html/app/homepage',
                query: { id: longTextInfo.author.uid },
              })
            "
          >
            <div class="add">查看更多</div>
            <van-image
              round
              width="40"
              height="40"
              :src="longTextInfo.author.avatar"
              class="img"
            />
            <div class="txt">{{ longTextInfo.author.nickname | maxLen }}</div>
          </div>
        </div>
        <div class="page3" id="long-rich-text">
          <div v-html="longTextInfo.content"></div>
        </div>
        <div class="page4" @click="open_app()">打开APP，给TA留言点赞</div>
      </template>
    </div>
    <TipMask :visible="showMask" @toggle="showMask = !showMask"></TipMask>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
import TopBanner from "../../components/TopBanner.vue";
import TipMask from '../../components/TipMask.vue';
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  components: {
    TopBanner,
    TipMask
  },
  mounted() {
    this.appUrl.onlyMo(); //只允许移动端打开
    if (
      this.$route.query.id == null ||
      this.$route.query.id == undefined ||
      this.$route.query.id == ""
    ) {
      this.appUrl.alertErr("无权访问");
      return;
    }
    document.title = this.record_title;
    this.id_record = this.$route.query.id;
    this.getLongTextInfo(this.id_record);
  },
  data() {
    return {
      showMask: false,
      record_title: "植物成长记", //页面title
      page_bool: true,
      page_num: 1,
      page_size: 20,
      id_record: null,
      url_web_detail: this.apiUrl.url_web_detail,
      down_url_iphone: this.appUrl.down_url_iphone, //Iphone下载链接
      down_url_android: this.appUrl.down_url_android, //Android下载链接
      url_iphone: "xzw://smallplants.cn/app/plant?id=" + this.$route.query.id + "&functionType=" + 1, //Iphone唤起APP链接
      url_android: "xzw://smallplants.cn/app/plant?id=" + this.$route.query.id + "&functionType=" + 1, //Android唤起APP链接
      user_section: {
        //用户信息
        user_id: null,
        nickName: null,
        img: null,
        title: null,
        txt: null,
        llNum: null,
        jlNum: null,
        datalist: null,
      },
      longTextInfo: null
    };
  },
  filters: {
    upString(v) {
      let text = ``;
      for (let i = 0; i < v.split("").length; i++) {
        if (i == v.split(" ").length - 1) {
          text = `${text}${v.split(" ")[i]}`;
        } else {
          text = `${text}${v.split(" ")[i]}/n`;
        }
        // console.log(text)
        return text;
      }
    },
    maxLen(v) {
      if (v && v.length >= 10) {
        return v.substring(0, 9) + "...";
      } else {
        return v;
      }
    }
  },
  methods: {
    open_record_imgview(i, imgs) {
      //预览大图
      ImagePreview({
        images: imgs,
        startPosition: i,
      });
    },
    getLongTextInfo(id) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "正在加载",
      });
      this.$axios
        .get(`${this.apiUrl.url_web_longtext_detail}?id=${id}`)
        .then((res) => {
          Toast.clear();
          if (res.data.code == 200) {
            this.longTextInfo = res.data.data
            this.preventDefaultDom()
          } else {
            this.appUrl.alertErr(res.data.msg);
          }
        })
        .catch(() => {
          Toast.clear();
          this.appUrl.alertErr("网络异常");
          console.log("err");
        });
    },
    preventDefaultDom() {
      this.$nextTick(() => {
        const doms = document.getElementById('long-rich-text')?.getElementsByTagName('a')
        if (doms && doms.length > 0) {
          doms.forEach(dom => {
            dom.onclick = (e) => {
              e.preventDefault()
              this.open_app()
            }
          })
        }
      })
    },
    dataFormat(dateTime, format) {
      var o = {
        "M+": dateTime.getMonth() + 1, //月份
        "d+": dateTime.getDate(), //日
        "h+": dateTime.getHours(), //小时
        "m+": dateTime.getMinutes(), //分
        "s+": dateTime.getSeconds(), //秒
        "q+": Math.floor((dateTime.getMonth() + 3) / 3), //季度
        "S": dateTime.getMilliseconds() //毫秒
      };
      if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (dateTime.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (var k in o)
        if (new RegExp("(" + k + ")").test(format)) format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return format;
    },
    open_app() {
      //下载app
      var ua = navigator.userAgent.toLowerCase();
      //系统判断
      if ((ua.indexOf('qq')>-1 && ua.indexOf('mqqbrowser')<0) ||
        ua.match(/MicroMessenger/i) == 'micromessenger') {
          // qq 或微信浏览器
        this.showMask = true
      } else if (ua.match(/iPhone/i) == "iphone") {
        //iphone
        console.log(ua.match(/iPhone/i));
        var loadTime = new Date();
        window.open(this.url_iphone);
        setTimeout(function () {
          var outTime = new Date();
          if (outTime - loadTime > 800) {
            window.open(this.down_url_iphone);
          }
        }, 1000);
      } else if (ua.match(/Android/i) == "android") {
        //android
        console.log(ua.match(/Android/i));
        loadTime = new Date();
        window.open(this.url_android);
        setTimeout(function () {
          var outTime = new Date();
          if (outTime - loadTime > 800) {
            window.open(this.down_url_android);
          }
        }, 1000);
      } else {
        console.log("请使用移动端登录");
        Toast.fail("请前往移动端下载");
      }
    },
  },
};
</script>
<style scoped>
.cont {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.record {
  width: 100%;
  height: auto;
  font-family: PingFang-SC-Medium, PingFang-SC;
}
.record .page1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background: #26262a;
  z-index: 10;
}
.record pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-weight: 500;
}
.record .page1 .dom1 {
  position: absolute;
  width: 60px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  top: 0;
  bottom: 0;
  left: 10px;
  margin: auto;
}
.record .page1 .dom1 .logo {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.record .page1 .dom2 {
  position: absolute;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
  left: 82px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.record .page1 .dom3 {
  position: absolute;
  width: 72px;
  height: 28px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 600;
  color: #26262a;
  text-align: center;
  line-height: 28px;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.record .page2 {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 48px;
}
.record .page2 .dom1 {
  width: 100%;
  padding: 25px 20px 0 20px;
  font-size: 20px;
  font-weight: 800;
  color: #26262a;
  line-height: 28px;
  box-sizing: border-box;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: PingFang-SC-Heavy, PingFang-SC;
}
.record .page2 .dom2 {
  position: relative;
  margin: 15px 20px 0 20px;
  height: 54px;
  background: #f7f7fa;
  border-radius: 4px;
}
.record .page2 .dom2 .img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}
.record .page2 .dom2 .txt {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 64px;
  font-size: 16px;
  font-weight: bold;
  color: #26262a;
  line-height: 22px;
}
.record .page2 .dom2 .add {
  position: absolute;
  width: 73px;
  height: 24px;
  background: #f7f7fa;
  border-radius: 2px;
  border: 1px solid #dbdeeb;
  font-size: 13px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #80869d;
  line-height: 24px;
  right: 14px;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
}
.record .page2 .dom3 {
  position: relative;
  margin: 16px 20px 0 20px;
  font-size: 14px;
  font-weight: 500;
  color: #26262a;
  line-height: 20px;
}
.record .page2 .dom3 pre {
  font-size: 15px;
  font-weight: 500;
  color: #26262a;
  line-height: 20px;
}
.record .page2 .dom4 {
  position: relative;
  margin: 14px 20px 0 20px;
  font-size: 13px;
  font-weight: 500;
  color: #80869d;
  line-height: 17px;
}
.record .page2 .dom4 span:nth-of-type(1) {
  display: inline-block;
  padding-right: 20px;
}
.record .page3 {
  position: relative;
  height: auto;
  margin-bottom: 80px;
  padding: 10px 20px;
  font-size: 14px;
}
.record .page3 >>> * {
  margin: 5px 0;
}
.record .page3 >>> img {
  max-width: 100%;
}
.record .page3 >>> h2 {
  font-size: 18px;
}
.record .page4 {
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  height: 44px;
  background: #26262a;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  line-height: 44px;
  text-align: center;
  z-index: 10;
}
</style>